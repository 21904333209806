<script>
export default {
  name: "title-text",
  data: () => ({}),
  props: {
    content: Object
  },
  computed: {
    isTitleEmpty() {
      return !this.content.title || this.content.title.trim() === "";
    },
    isDescriptionEmpty() {
      return !this.content.description || this.content.description.trim() === "";
    }
  }
}
</script>

<template>
  <div class="title-text">
    <h2
      v-if="!isTitleEmpty "
      v-html="content.title"
    ></h2>
    <p
      v-if="!isDescriptionEmpty "
      v-html="content.description"
    ></p>
  </div>
</template>

<style scoped lang="scss">
.title-text {
  h2 {
    color: #424242;
    font-family: 'Commissioner-Regular';
    font-size: 24px;
    margin-bottom: 27px;


    ::v-deep strong {
      font-family: 'Commissioner-Bold';
    }
  }

  p {
    color: #424242;
    font-size: 14px;
    font-family: 'Commissioner-Regular';

    ::v-deep strong {
      font-family: 'Commissioner-Bold';
    }


    > ::v-deep ul {
      li {
        margin-bottom: 10px;
        position: relative;
        padding-left: 15px;

        &:before {
          content: '';
          width: 5px;
          height: 5px;
          background: #424242;
          border-radius: 50%;
          overflow: hidden;
          position: absolute;
          left: 0;
          top: 5px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    > ::v-deep p {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>